import React, { useState } from "react";
import "../../../styling/templates.css";

function App() {
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const onSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    formData.append(
      "subject",
      formData.get("subject") || "ECO Spindles - Products Email Form"
    );
    formData.append("access_key", "3b5055db-a06b-4b8d-9ce0-2e62dd32aa39");

    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    try {
      const res = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: json,
      });

      if (res.ok) {
        const response = await res.json();
        if (response.success) {
          // Set the success message and redirect
          setSuccessMessage("Form submitted successfully!");
          setErrorMessage(""); // Clear any previous error message
          window.location.href = formData.get("redirect");
        } else {
          setErrorMessage("Form submission failed. Please try again.");
          setSuccessMessage(""); // Clear any previous success message
        }
      } else {
        setErrorMessage("Form submission failed. Please try again.");
        setSuccessMessage(""); // Clear any previous success message
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setErrorMessage("An error occurred. Please try again later.");
      setSuccessMessage(""); // Clear any previous success message
    }
  };

  return (
    <div>
      <form onSubmit={onSubmit}>
        <input
          type="hidden"
          name="redirect"
          value="info@ecospindles.com; admin@3cs.lk"
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: "70px",
            width: "70%",
          }}
        >
          <input
            type="email"
            name="Consumer Email:"
            required
            className="inputStyle2"
            placeholder="Your Email here"
            style={{ marginRight: "10px", fontSize:"15px" }}
          />
          <button
            type="submit"
            className="btn submitButton-3 btn-lg"
            id="button"
            style={{ marginTop: "-30px" }}
          >
            Send
          </button>
        </div>
        {successMessage && (
          <div className="success-message">{successMessage}</div>
        )}
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        <br />
        <br />
      </form>
    </div>
  );
}

export default App;
